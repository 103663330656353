@import url("https://fonts.googleapis.com/css?family=Jura:400,400i,700");
html,
body {
padding: 0;
margin: 0;
background-color: #EADBCA;
}

* {
box-sizing: border-box;
}

ul, ol {
padding: 0;
margin: 0;
}

li {
padding: 0;
margin: 0 0 0 1rem;
}

a {
text-decoration: none;
}

ul ul {
margin: 0 0 0 1em;
}

ul ul ul {
margin: 0 0 0 2em;
}

.hcClrBg_Info {
background-color: #1f96ad;
}

.hcClrBg_InfoLight {
background-color: #ebf7fa;
}

.hcClrBg_Reward {
background-color: #9fcf3f;
}

.hcClrBg_RewardLight {
background-color: #d6ebad;
}

.hcClrBg_Error {
background-color: #de6e6e;
}

.hcClrBg_ErrorLight {
background-color: #ffaa99;
}

.hcClrBg_Caution {
background-color: #e2c765;
}

.hcClrBg_CautionLight {
background-color: #efe0a9;
}

.hcTxt_Caution {
color: #c54a11;
}

.hcClrBg_Grey05 {
background-color: #f2f2f2;
}

.hcClrBg_Grey10 {
background-color: #e6e6e6;
}

.hcClrBg_Grey15 {
background-color: #d9d9d9;
}

.hcClrBg_Grey20 {
background-color: #cccccc;
}

.hcClrBg_Grey30 {
background-color: #b3b3b3;
}

.hcClrBg_Grey70 {
background-color: #4d4d4d;
}

.hcClrBg_Grey80 {
background-color: #333333;
}

.hcClrBg_Grey90 {
background-color: #1a1a1a;
}

.hcClrTxt {
color: #1a1a1a;
}

.hcClrTxt_Grey {
color: gray;
}

.hcClrTxt_Dark {
color: #000;
}


a {
color: #0087d4;
}

.colorBgGrey {
background-color: #f6f6f6;
}

.colorBgGreyLight {
background-color: #fafafa;
}

.colorBgGreyPlus {
background-color: #dddddd;
}

.hcTxtColorGreyMid {
color: #999999;
}

body {
font-family: "Jura", sans-serif;
font-weight: 300;
font-size: 18px;
line-height: 140%;
font-style: normal;
color: #1a1a1a;
}

h1 {
color: #265955;
font-size: 1.5em;
line-height: 130%;
font-weight: 700;
}

h2 {
color: #265955;
font-size: 1.3em;
line-height: 130%;
font-weight: 700;
}

h3 {
color: #265955;
font-size: 1em;
line-height: 130%;
font-weight: 700;
}

h4 {
color: #265955;
font-size: 0.85em;
line-height: 130%;
font-weight: 300;
font-style: italic;
}

@media (min-width: 600px) {
body {
font-size: 18px;
line-height: 150%;
}

h1 {
font-size: 2em;
}

h1, h2, h3, h4 {
line-height: 150%;
}
}
h1, h2, h3, h4 {
margin-top: 0;
margin-bottom: 0.3em;
}

p {
margin-top: 0;
margin-bottom: 1em;
}

strong {
font-weight: 700;
}

.hcSmallTxt {
font-size: 0.85rem;
}

.hcMonospace {
font-family: monospace;
}

.hcNotBold {
font-weight: 300;
}

.icon {
width: 1.3rem !important;
height: auto !important;
}

.hcIconText {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
}
.hcIconText .icon {
margin-right: 0.5rem;
}

.hcIcon svg {
display: block;
width: 2rem;
height: auto;
}

.hcIconFill {
fill: #3e3e3e;
}

.hcIconStroke {
stroke: #3e3e3e;
stroke-width: 3px;
}

.hcIconSmall svg {
width: 1rem;
}

.hcPointer {
cursor: pointer;
}

.hcBasicSideMargin {
padding-left: 2rem;
padding-right: 2rem;
}

.hcBasicPading {
padding: 1rem;
}

.hcMarginBottom05 {
margin-bottom: 0.5rem;
}

.hcMarginBottom1 {
margin-bottom: 1rem;
}

.hcMarginBottom1_5 {
margin-bottom: 1.5rem;
}

.hcMarginBottom2 {
margin-bottom: 2rem;
}

.hcMarginBottom3 {
margin-bottom: 3rem;
}

.hcMarginBottom4 {
margin-bottom: 4rem;
}

.hcMarginBottom5 {
margin-bottom: 5rem;
}

.hcMarginBottom15 {
margin-bottom: 15rem;
}

.hcMarginTop05 {
margin-top: 0.5rem;
}

.hcMarginTop1 {
margin-top: 1rem;
}

.hcMarginTop2 {
margin-top: 2rem;
}

.hcMarginTop3 {
margin-top: 3rem;
}

.hcMarginTop4 {
margin-top: 4rem;
}

.hcMarginTop5 {
margin-top: 5rem;
}

.hcMarginBackground {
padding-top: 4rem;
padding-bottom: 4rem;
}

.hcNestedItemMargin1 > * {
margin-right: 1rem;
margin-bottom: 1rem;
}

.hcMarginLeft05 {
margin-left: 0.5rem;
}

.hcMarginLeft1 {
margin-left: 1rem;
}

.hcMarginLeft2 {
margin-left: 2rem;
}

.hcMarginLeft3 {
margin-left: 3rem;
}

.hcMarginRight05 {
margin-right: 0.5rem;
}

.hcMarginRight1 {
margin-right: 1rem;
}

.hcMarginRight2 {
margin-right: 2rem;
}

.hcMarginRight3 {
margin-right: 3rem;
}

.hcAlignHorizontal {
display: flex;
flex-direction: row;
}

.hcAlignVertical {
display: flex;
flex-direction: column;
}

.hcAlignLeft {
display: flex;
justify-content: flex-start;
}

.hcAlignRight {
display: flex;
justify-content: flex-end;
}

.hcAlignVertical.alignRight {
display: flex;
align-items: flex-end;
}

.hcAlignLeftRight {
display: flex;
justify-content: space-between;
}

.hcAlignCenter {
display: flex;
justify-content: center;
}

.hcAlignVertical.alignCenter {
display: flex;
align-items: center;
}

.hcRowLeft {
display: flex;
justify-content: flex-start;
align-items: center;
}

.hcRowLeftTop {
display: flex;
justify-content: flex-start;
align-items: flex-start;
}

.hcRowRight {
display: flex;
justify-content: flex-end;
align-items: center;
}

.hcRowRightTop {
display: flex;
justify-content: flex-end;
align-items: flex-start;
}

.hcRowJustify {
display: flex;
justify-content: space-between;
align-items: center;
}

.hcRowJustifyTop {
display: flex;
justify-content: space-between;
align-items: flex-start;
}

.hcTxtLeft {
text-align: left;
}

.hcTxtRight {
text-align: right;
}

.hcTxtCenter {
text-align: center;
}

.hcLists > * {
list-style: none;
margin-top: 0;
margin-left: 0;
margin-right: 0;
margin-bottom: 1.2rem;
padding-top: 0;
padding-left: 0;
padding-right: 0;
padding-bottom: 1.2rem;
border-bottom: 1px solid #d9d9d9;
}

.hcListsItemRow {
display: flex;
flex-direction: column;
margin-bottom: 1rem;
}
.hcListsItemRow > * {
margin-bottom: 1rem;
}

@media (min-width: 800px) {
.hcListsItemRow {
flex-direction: row;
}
.hcListsItemRow > * {
margin-bottom: 0;
}
}
.hcList {
display: flex;
flex-direction: column;
}
.hcList li {
margin: 0.5rem 0 0.5rem 0;
}

.hcList > * {
display: flex;
flex-direction: column;
justify-content: space-between;
border-top: 1px solid #d9d9d9;
padding-top: 0.7rem;
padding-bottom: 0.7rem;
}

.hcList > *:hover {
background-color: #d9d9d9;
margin: 0 -1rem;
padding-left: 1rem;
padding-right: 1rem;
}

.hcListDisctinctLines > * {
border-top: none;
}

.hcListDisctinctLines > *:nth-child(odd) {
background-color: #f2f2f2;
margin: 0 -1rem;
padding-left: 1rem;
padding-right: 1rem;
}

.hcListDisctinctLines > *:nth-child(odd):hover {
background-color: #d9d9d9;
}

.hcListDisctinctLines > *:nth-child(even) {
}

.hcList > * > * {
flex: 1;
margin-right: 1rem;
}

@media (min-width: 600px) {
.hcList > * {
flex-direction: row;
display: flex;
}
}
.hcListItemLong {
flex: 2;
}

.hcListHeader {
background: none;
border: none;
}

.hcListHeader:hover {
background: none;
border: none;
}

.hcListBasicResult {
cursor: pointer;
}

.hcRowCard {
background-color: #fff;
padding: 1rem;
margin: 0 -1rem 0.5rem -1rem;
border-radius: 2px;
}

.hcRowCard > * {
display: flex;
flex-direction: column;
justify-content: flex-start;
}

.hcListItemBlock {
padding: 0;
transition: background-color 0.3s;
margin-bottom: 1.5rem;
}

.hcListItemBlock > * {
padding: 1rem 2rem;
}

.hcListItemDPPBlock {
flex: 2;
display: flex;
flex-direction: column;
justify-content: space-between;
}

.hcKwic {
border-bottom: 3px dotted #e7da67;
}

.hcListItemThumb {
overflow-y: scroll;
display: flex;
align-items: flex-start;
justify-content: center;
background-color: #3b3b3b;
padding-top: 0.5rem;
}

.hcListItemThumb div {
height: 170px;
}

.hcListItemThumb img {
width: 200px !important;
height: auto !important;
}

.hcResultsHeader {
display: flex;
justify-content: space-between;
}

.hcMessageBar {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
font-size: 0.85rem;
line-height: 140%;
font-style: italic;
background-color: whitesmoke;
border-radius: 4px;
}

.hcMessageBar > * {
padding: 0.5rem;
}

.notificationInform {
color: #005466;
background-color: #ccf6ff;
}
.notificationInform .hcIconFill {
fill: #005466;
}
.notificationInform .hcIconStroke {
stroke: #005466;
}

.notificationCaution {
color: #967d1c;
background-color: white;
}
.notificationCaution .hcIconFill {
fill: #967d1c;
}
.notificationCaution .hcIconStroke {
stroke: #967d1c;
}

.notificationError {
color: #3b0b00;
background-color: #ffb2a1;
}
.notificationError .hcIconFill {
fill: #3b0b00;
}
.notificationError .hcIconStroke {
stroke: #3b0b00;
}

.notificationReward {
color: #476018;
background-color: #f1f8e5;
}
.notificationReward .hcIconFill {
fill: #476018;
}
.notificationReward .hcIconStroke {
stroke: #476018;
}

.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns {
display: flex;
flex-direction: column;
}

.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns > * {
flex-basis: 100%;
}

@media (min-width: 800px) {
.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns {
flex-direction: row;
}

.hc2columns > * {
flex-basis: 50%;
}

.hc3columns > * {
flex-basis: 33%;
}

.hc4columns > * {
flex-basis: 25%;
}

.hc5columns > * {
flex-basis: 20%;
}

.hc6columns > * {
flex-basis: 15%;
}
}
.hcColumnsAuto {
display: flex;
flex-direction: column;
gap: 1rem;
width: 100%;
}
.hcColumnsAuto > * {
flex: 1;
}

@media (min-width: 800px) {
.hcColumnsAuto {
flex-direction: row;
gap: 2rem;
}
}
.hcCell--x2 {
flex: 2;
}

.hcCell--x3 {
flex: 3;
}

.hcCell--x4 {
flex: 4;
}

.hcCell--x5 {
flex: 5;
}

button {
font-size: 0.7rem;
padding: 0.5rem;
border-radius: 4px;
background-color: rgba(0, 0, 0, 0.07);
border: 1px solid rgba(0, 0, 0, 0.09);
cursor: pointer;
font-weight: 700;
margin: 0 0.3rem;
max-height: 40px;
}

.hcImageButttons {
max-height: 35px;
max-width: 35px;
background-color: rgba(0, 0, 0, 0.07);
border: 1px solid rgba(0, 0, 0, 0.09);
padding: 0.25rem;
}

.hc-loud {
padding: 0.8rem;
font-size: 1rem;
}

.hc-small {
padding: 0.3rem;
font-size: 0.6rem;
}

.hc-subtle {
background: none;
border: none;
box-shadow: none;
color: #0087d4;
}

.hcfixedSideButton {
width: 2rem;
padding: 0.2rem;
border-radius: 0 4px 4px 0;
}

.hcToggle button {
margin: 0 0rem;
border-radius: 0px;
border-right: 0px;
}
.hcToggle > *:first-child {
border-radius: 4px 0 0 4px;
}
.hcToggle > *:last-child {
border-radius: 0 4px 4px 0;
border-right: 1px solid rgba(0, 0, 0, 0.09);
}

.buttonRemove:before {
content: "";
width: 20px;
height: 20px;
display: block;
}

input, select, option, textarea {
width: 100%;
padding: 0.5rem;
border: 1px solid #e1ebf3;
border-radius: 4px;
font-size: 1rem;
font-weight: 300;
color: #3e3e3e;
height: 40px;
}

.hcForm input, .hcForm select, .hcForm option, .hcForm textarea {
background-color: #f0f5f9;
max-width: 480px;
background-color: #f0f5f9;
}
.hcForm input[type=checkbox] {
background-color: #f0f5f9;
max-width: 30px;
background-color: #f0f5f9;
}
.hcForm textarea {
height: auto;
min-height: 150px;
}

input::placeholder {
color: gray;
font-style: italic;
font-size: 0.85rem;
}

.hcLabel {
color: #999999;
text-transform: uppercase;
font-size: 0.85rem;
}

.hcList2 {
margin-left: 0;
margin-right: 0;
padding-left: 0;
padding-right: 0;
}
.hcList2 li {
margin-left: 0;
margin-right: 0;
padding-left: 0;
padding-right: 0;
}

.hcList2NoIcon {
list-style: none;
}

.hcStickyFooter {
position: fixed;
width: 100%;
min-width: 40px;
bottom: 0;
}

.hcStickyFooterLook {
background-color: #fff;
padding-top: 1rem;
padding-bottom: 1rem;
border-top: 1px solid #d9d9d9;
}

.hcTextSnippit::before {
content: " ...";
}

.hcTextSnippit::after {
content: "... ";
}

.hcDatasetEntity {
font-size: 0.85rem;
padding: 0rem 0 0rem 0.5rem;
border: 1px solid #e7e7e7;
border-radius: 0.5rem 0 0 0.5rem;
margin: 0 0.5rem 0.5rem 0;
display: flex;
flex-direction: row;
justify-content: space-between;
}

.hcDatasetEntityAmount {
border-radius: 3px;
padding: 0 0.5rem;
margin: -1px -2px -1px 0;
background-color: #e7e7e7;
color: #3e3e3e;
min-width: 3.5rem;
text-align: right;
}

.hcPartnerLogo img {
width: 100px !important;
height: auto !important;
}

.hcDensity {
width: 100%;
min-width: 100px;
background-color: #e3e3e3;
}

.hcDensityBar {
background-color: #3aa5b0;
font-size: 0.5rem;
color: #fff;
padding: 0 0 0 0.5rem;
}

.hcUriType {
font-family: monospace;
font-size: 0.85rem;
}

.hcBreakAll {
overflow-wrap: break-word;
word-break: break-all;
hyphens: auto;
}

.hcBarDataset {
display: flex;
flex-direction: column;
padding-bottom: 1rem;
}

@media (min-width: 600px) {
.hcBarDataset {
flex-direction: row;
}

span {
display: flex;
flex-direction: row;
align-items: baseline;
padding-right: 1rem;
}

select {
background-color: unset;
}
}
.hcBorderBottom {
border-bottom: 1px solid #ededed;
}

.hcMapPlaceholder {
width: 100%;
height: 250px;
background-size: cover;
background-position: center;
}

hr {
border: none;
height: 1px;
margin-top: 0;
margin-bottom: 0;
color: rgba(0, 0, 0, 0.15);
/* old IE */
background-color: rgba(0, 0, 0, 0.15);
/* Modern Browsers */
}

.hcStickOutBox {
margin: -1rem;
padding: 1rem;
background-color: #f2f2f2;
}

.hcStickOutBoxAside {
margin: 0 -1rem;
padding: 0 1rem;
background-color: #f2f2f2;
}

.hcRoundedCorners {
border-radius: 4px;
}

.hcRoundedCornersTop {
border-radius: 4px 4px 0 0;
}

.hcTextTruncate {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 200px;
}

.hcMsg--info {
background-color: #e6f6ff;
border: 1px solid #b3d4e6;
color: #265873;
border-radius: 4px;
position: absolute;
width: 100%;
max-width: 720px;
padding: 1rem;
box-shadow: 2px 2px 2px #d9d9d9;
}

.hcPointer {
cursor: pointer;
}

.hcContentContainer {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
}

.hcContentContainer > * {
width: 100%;
max-width: 1200px;
}

.hcBlockText {
width: 100%;
}
.hcBlockText ol, .hcBlockText ul {
margin-left: 1rem;
}

.hcBlockText > * {
width: 100%;
}

.hcHeadSpace {
height: 25vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}

@media (min-width: 800px) {
.hcHeadSpace {
flex-direction: row;
align-items: center;
justify-content: space-between;
}
}
.hcBlockScroll {
height: 100vh;
overflow-y: auto;
}

.hcBlockHeroWithTeaser {
height: 66vh;
display: flex;
justify-content: center;
}

.hcLayoutBasicMainAside, .hcLayoutBasicMainFixedAside {
display: flex;
flex-direction: column;
align-items: flex-start;
}

.hcLayoutAside {
width: 100%;
}

.hcLayoutMain {
width: 100%;
}

@media (min-width: 800px) {
.hcLayoutBasicMainAside {
flex-direction: row;
}

.hcLayoutAside {
width: 300px;
}

.hcLayoutMain {
width: calc(100vw - 300px);
min-width: calc(800px - 300px);
}
}
@media (min-width: 800px) {
.hcLayoutBasicMainFixedAside {
flex-direction: row;
justify-content: flex-end;
}
.hcLayoutBasicMainFixedAside .hcLayoutAside {
width: 300px;
position: fixed !important;
top: 0;
left: 0;
bottom: 0;
}
.hcLayoutBasicMainFixedAside .hcLayoutMain {
width: calc(100vw - 300px);
margin-left: 300px;
}
.hcLayoutBasicMainFixedAside .asideWithProductBar {
display: flex;
}
.hcLayoutBasicMainFixedAside .asideWithProductBar > *:last-child {
margin-left: 4rem;
}
.hcLayoutBasicMainFixedAside .hcStickyFooter {
width: calc(100vw - 300px);
}
}
@media (min-width: 1300px) {
.hcLayoutBasicMainFixedAside {
flex-direction: row;
justify-content: flex-end;
}
.hcLayoutBasicMainFixedAside .hcLayoutAside {
width: 400px;
}
.hcLayoutBasicMainFixedAside .hcLayoutMain {
width: calc(100vw - 400px);
margin-left: 400px;
}
.hcLayoutBasicMainFixedAside .asideWithProductBar > *:last-child {
margin-left: 4rem;
width: 100%;
}
.hcLayoutBasicMainFixedAside .hcStickyFooter {
width: calc(100vw - 400px);
}
}
.hcLayoutFacet-Result {
display: flex;
flex-direction: column;
align-items: flex-start;
}

.hcLayoutFacets {
width: 100%;
}

.hcLayoutResults {
width: 100%;
}

.hcLayoutFacetsToggel {
display: flex;
height: 230px;
flex-direction: row;
overflow-x: auto;
background-color: #fafafa;
}
.hcLayoutFacetsToggel div {
width: 250px;
margin-right: 3rem;
}

#showFacets {
position: fixed;
top: 45vh;
left: 0;
}

@media (min-width: 800px) {
.hcLayoutFacet-Result {
flex-direction: row;
}

.hcLayoutFacets {
width: 500px;
}

.hcLayoutResults {
width: calc(100% - 350px);
}

.hcLayoutFacetsToggel {
display: block;
height: auto;
flex-direction: column;
background: none;
}
.hcLayoutFacetsToggel div {
width: 100%;
margin-right: auto;
}

#showFacets {
display: none;
position: fixed;
}
}
.hcThumbsRow {
display: flex;
flex-direction: row;
}

.hcThumbsRow img {
margin: 1rem 0 1rem 1rem;
cursor: pointer;
}

.hclayoutTextImageDivider {
display: flex;
flex-direction: row;
height: calc(100vh - 150px);
}

.hcViewer {
width: 45%;
}

.hcMeta {
max-width: 250px;
}

.hcText {
display: flex;
flex-direction: row;
justify-content: center;
}

.hcText > * {
max-width: 550px;
margin-bottom: 500px;
}

.hcLayout-right-sidebar {
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
}

.hcLayout-right-sidebar-main {
width: 100%;
}

.hcLayout-right-sidebar-aside {
width: 100%;
}

@media (min-width: 800px) {
.hcLayout-right-sidebar {
flex-direction: row;
}

.hcLayout-right-sidebar-main {
width: calc(100% - 250px - 2rem);
}

.hcLayout-right-sidebar-aside {
width: 250px;
}
}
.hcSidebar div {
margin-bottom: 1.5rem;
}

.hcSpitscreen {
display: flex;
flex-direction: column;
}

.hcSpitscreen > * {
display: flex;
flex-direction: column;
justify-content: flex-start;
}

@media (min-width: 1400px) {
.hcSpitscreen {
flex-direction: row;
}
}
.hcLayoutWideCols {
display: flex;
flex-direction: column;
width: 100%;
}
.hcLayoutWideCols > * {
width: 100%;
max-width: 50vw;
}

@media (min-width: 800px) {
.hcLayoutWideCols {
flex-direction: row;
}

.hcWideColsFixedWidth {
width: 100%;
max-width: 25vw;
}
}
.hcLayoutWideColsInner {
width: 100%;
}

.hcLayoutStaticAside {
display: flex;
flex-direction: row;
flex-wrap: wrap;
}

.hcLayoutStaticAsideM {
width: 100%;
background-color: #88d0db;
max-height: 50vh;
}

.hcLayoutStaticAsideL {
width: 50%;
background-color: #ecd0db;
}

.hcLayoutStaticAsideR {
width: 50%;
background-color: #24d0db;
}

@media (min-width: 1100px) {
.hcLayoutStaticAsideM {
width: 50%;
max-height: none;
order: 2;
}

.hcLayoutStaticAsideL {
width: 25%;
order: 1;
}

.hcLayoutStaticAsideR {
width: 25%;
order: 3;
}
}
.navigation a {
color: #3e3e3e;
font-weight: 700;
font-size: 14px;
padding: 0.2rem;
margin-right: 0.5rem;
}
.navigation .navigation-sub > * {
font-weight: 300;
}
.navigation li {
list-style: none;
}
.navigation .navigation-secundairy-items {
display: none;
}

.hcPageHeaderSimple {
display: flex;
flex-direction: column;
justify-content: space-between;
font-size: 0.9rem;
width: 100%;
}

nav {
padding-top: 8px;
}

.hcPageHeaderSimple nav {
display: flex;
flex-direction: row;
height: 80%;
align-items: center;
line-height: 130%;
}
.hcPageHeaderSimple nav a, .hcPageHeaderSimple nav div, .hcPageHeaderSimple nav .headerLink {
height: 80%;
padding: 16px 20px;
display: flex;
align-items: center;
cursor: pointer;
}
.hcPageHeaderSimple nav a:hover {
background-color: rgba(255, 255, 255, 0.11);
}
.hcPageHeaderSimple nav a:first-child {
margin-left: -1rem;
}
.hcPageHeaderSimple .hcMenuButton {
border: none;
background: none;
font-size: 0.9rem;
color: #fff;
display: flex;
flex-direction: row;
align-items: center;
margin-left: 2rem;
}
.hcPageHeaderSimple .hcMenuButton img {
width: 0.7rem;
height: 0.7rem;
margin-left: 0.5rem;
}

.hcPageHeaderSimple > * {
height: 100%;
display: flex;
align-items: center;
}

.hcSecundairNav {
display: none;
}

@media (min-width: 600px) {
.hcPageHeaderSimple {
flex-direction: row;
}

.hcSecundairNav {
display: flex;
}

.hcMenuButton {
display: none;
}
}
.hcBrand {
display: flex;
flex-direction: row;
justify-content: flex-start;
height: 100%;
}

.hcBrandLogo img {
height: 33px;
width: auto;
}

.hcBrandName {
padding: 1.5rem 1rem 1rem 1rem;
font-weight: 700;
}

@media (min-width: 600px) {
.hcBrandLogo img {
height: 60px;
width: auto;
}
}
.hcFullscreenMenu {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
font-size: 1.5rem !important;
align-items: flex-start;
justify-content: flex-start;
}
.hcFullscreenMenu nav {
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
min-height: 50vh;
}
.hcFullscreenMenu nav a {
padding: 0 0rem;
}
.hcFullscreenMenu #hcMenuButton {
position: fixed;
top: 30px;
right: 30px;
}
.hcFullscreenMenu .hcSecundairNav {
display: flex;
}
.hcFullscreenMenu nav a:first-child {
margin-left: 0rem;
}
.hcFullscreenMenu .hcBrand {
margin-left: 0rem;
margin-top: 6rem;
margin-bottom: 3rem;
}
.hcFullscreenMenu .hcPageHeaderSimple {
flex-direction: column;
}

.hcTabs {
display: flex;
width: 100%;
}
.hcTabs .hcSelectedTab {
font-weight: 700;
background-color: #f2f2f2;
}

.hcTabsVert {
flex-direction: column;
}
.hcTabsVert .hcTabLabels {
display: flex;
flex-direction: column;
}

.hcTabsHoriz {
flex-direction: column;
}
.hcTabsHoriz .hcTabLabels {
display: flex;
flex-direction: row;
}

.hcTabLabels {
width: 100%;
position: relative;
z-index: 100;
}

.hcTabAllContent {
width: 100%;
position: relative;
z-index: 10;
background-image: linear-gradient(#f2f2f2, #fff 50px);
}

@media (min-width: 500px) {
.hcTabsVert {
flex-direction: row;
}
.hcTabsVert .hcTabLabels {
width: 20%;
}
.hcTabsVert .hcTabAllContent {
width: 80%;
}
}
.hcTabContent {
width: 100%;
}

.hcTabLabel {
cursor: pointer;
padding: 0.5rem 1rem;
display: flex;
flex-direction: row;
align-items: center;
}

.hcFormItem {
display: flex;
flex-direction: column;
width: 100%;
}

.hcFormItem > * {
width: 100%;
}

.hcFeedbackError {
border-bottom: 3px solid #d42600 !important;
}

.hcFeedbackSucces {
border-bottom: 3px solid #d42600 !important;
}

.hcFormItemRow {
display: flex;
flex-direction: column;
justify-content: space-between;
}

.hcFormItemExtra {
width: 100%;
}

@media (min-width: 1280px) {
.hcFormItemRow {
flex-direction: row;
}

.hcFormItemExtra {
width: 60%;
display: flex;
align-items: center;
padding-left: 1rem;
}
}
.hcProvenanceComp {
width: 100%;
display: flex;
flex-direction: column;
display: none;
}
.hcProvenanceComp textarea {
height: 30px !important;
}

.hcFormStack {
display: flex;
flex-direction: column;
border-top: 1px solid #e8e8e8;
padding-top: 2rem;
width: 100%;
}

.hcFormStack > * {
width: 100%;
display: flex;
flex-direction: column;
}

.hcStackInfo {
margin-bottom: 2rem;
margin-right: 2rem;
}

@media (min-width: 800px) {
.hcFormStack {
flex-direction: row;
}

.hcStackInfo {
width: calc(30% - 2rem);
}

.hcStackFormItems {
width: 70%;
padding-top: 1.5rem;
}
}
.hcProductBar {
width: 100vw;
padding: 0 1.5rem;
display: flex;
justify-content: flex-start;
background-color: #494949;
color: #3e3e3e;
}
.hcProductBar .icon {
width: 1rem;
height: auto;
}
.hcProductBar .logo {
width: 3rem;
height: auto;
margin-left: -1rem;
}

@media (min-width: 800px) {
.hcProductBar {
position: fixed;
z-index: 999;
top: 0;
left: 0;
width: 4rem;
overflow-y: hidden;
height: 100vh;
flex-direction: column;
align-items: flex-start;
}
.hcProductBar .icon {
width: 1.5rem;
margin-right: 1rem;
}
.hcProductBar nav {
display: flex;
flex-direction: column;
}
.hcProductBar nav > * {
margin-bottom: 2rem;
width: 200px;
color: #3e3e3e;
}

.hcProductBar + .hcContentContainer {
margin-left: 4rem;
width: calc(100% - 4rem);
}
}
@media (max-width: 800px) {
.hcProductBar .hcHeadSpace {
height: auto;
}
}

.hcFacet {
margin-bottom: 1rem;
max-width: 250px;
font-size: 0.85rem;
}

.hcFacetItems {
display: flex;
flex-direction: column;
}

.hcFacetItem {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}
.hcFacetItem .hcFacetItemSpan {
display: flex;
flex-direction: row;
align-items: center;
height: 15px;
}
.hcFacetItem .hcFacetItemSpan input[type=checkbox] {
width: 15px;
background-color: #eee090;
}

.hcFacetItemSelected {
font-weight: 700;
}

.hcFacetTitle {
text-transform: uppercase;
font-size: 0.85rem;
font-weight: 700;
display: flex;
flex-direction: row;
justify-content: space-between;
cursor: pointer;
}

.hcFacetCount {
color: #999999;
}

.hcFacetSearch {
display: flex;
justify-content: space-between;
}
.hcFacetSearch input {
border-radius: 4px 0 0 4px;
border-right: 0px;
background-color: #F4ECE2;
}
.hcFacetSearch button {
border-radius: 0 4px 4px 0;
color: #dddddd;
background-color: #265955;
}

.hcSelectedFacet {
font-size: 0.85rem;
padding: 0.2rem 0.5rem;
background-color: #F4ECE2;
border: 1px solid #265955;
margin: 0 0.5rem 0.5rem 0;
cursor: pointer;
border-radius: 6px;
}

.hcSelectedFacet:after {
margin-left: 0.3rem;
opacity: 0.4;
}

.hcSelectedFacetType {
color: rgba(0, 0, 0, 0.4);
font-style: italic;
margin-right: 0.3rem;
}

.hcFacetFilter input {
padding: 0.3rem;
font-size: 0.85rem;
height: auto;
border: 1px solid #ebebeb;
}

.hcFacetFilter input::placeholder {
font-style: italic;
color: rgba(0, 0, 0, 0.4);
}

.hcIconHelp img {
width: 1.3rem;
height: auto;
cursor: pointer;
}

.hcFacetHelp {
display: none;
position: absolute;
background-color: #25a39a;
padding: 0.5rem;
width: 250px;
}

.hcPagination {
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
}

.hcPagination > * {
margin: 0 0.5rem;
}

.hcPagination a {
padding: 0.5rem;
}

.hcModal {
background-color: rgba(0, 0, 0, 0.85);
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 10000;
}

.hcModelContentWrap {
background-color: #ffffff;
height: calc(100vh - 6rem);
width: calc(100vw - 4rem);
margin: 6rem 0;
overflow-y: scroll;
}

.hcQueryBuilder {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
font-size: 1.1rem;
font-weight: 700;
}

.hcQBuildBlock {
padding: 0.2rem 0.3rem;
display: flex;
align-items: center;
margin-left: -1px;
height: 2.5rem;
}
.hcQBuildBlock .hcIconFill {
fill: #b3b3b3 !important;
}

.hcQBuildBlock:hover {
box-shadow: 2px 2px 2px #d9d9d9;
}

.hcQueryBuilderRow {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
margin-bottom: 1rem;
}

.hcQBuildBlockInt {
border: 1px solid #cccccc;
cursor: pointer;
}

.hcQBuildBlockChoice {
display: flex;
flex-direction: column;
margin-bottom: -7.5rem;
}

.hcQBuildBlockChoice > * {
border-top: 1px solid #cccccc;
border-left: 1px solid #cccccc;
border-right: 1px solid #cccccc;
cursor: pointer;
padding: 0.2rem 0.3rem;
width: 8rem;
}

.hcQBuildBlockChoice > *:last-child {
border-bottom: 1px solid #cccccc;
border-radius: 0 0 4px 4px;
}

.hcQBuildBlockChoice > *:first-child {
border-radius: 4px 4px 0 0;
}

.hcQBuildBlockBrowse {
background-color: #e6e6e6;
height: 50vh;
margin-bottom: calc(2.5rem - 50vh);
z-index: 200;
padding: 0.2rem 0.3rem;
width: 20rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
box-shadow: 2px 2px 2px #d9d9d9;
}
.hcQBuildBlockBrowse input {
font-size: 0.85rem;
border: 1px solid #cccccc;
}
.hcQBuildBlockBrowse .hcList {
font-weight: 300;
font-size: 0.85rem;
flex: 1;
overflow-y: auto;
width: 100%;
}
.hcQBuildBlockBrowse .hcList > * {
margin: 0;
padding-left: 1rem;
}

.hcCodeEditor {
display: flex;
flex-direction: row;
}
.hcCodeEditor textarea {
border: none;
padding: 0;
min-height: 40vh;
}

.hccodeTxt {
font-family: "Lucida Console", Monaco, monospace;
font-weight: 300;
font-size: 0.85rem;
line-height: 1.5rem;
}

.hcLineNumbers {
display: flex;
flex-direction: column;
text-align: right;
font-size: 0.7rem;
}
.hcLineNumbers > * {
text-align: right;
display: block;
}

.hcLogicBox {
width: 100%;
}

.hcLogicBox-Row {
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 0.5rem;
margin-left: -1.5rem;
padding: 0.5rem;
}

.hcLogicBox-RowEditDelete {
display: flex;
flex-direction: row;
justify-content: flex-end;
}
.hcLogicBox-RowEditDelete > * {
margin-right: 1rem;
}

.hcLogicBox-group {
margin-bottom: 1rem;
border-left: 5px solid gray;
padding-left: 2rem;
}

.hcLogicBox-group-operator {
font-weight: 700;
margin-bottom: 1rem;
margin-left: -0.5rem;
color: gray;
}

.hcTree {
margin: 0;
padding: 0;
}
.hcTree li {
list-style: none;
margin-top: 0;
margin-left: 0;
margin-right: 0;
padding: 0;
display: flex;
flex-direction: column;
}
.hcTree li ul {
margin: 0;
}
.hcTree li ul li {
margin-top: 0;
margin-right: 0;
margin-left: 2rem;
}
.hcTree li:hover {
font-weight: 700;
}
.hcTree li:hover ul {
font-weight: 300;
}

.hcTreeIcon {
width: 25px;
height: auto;
}

.hcTreeListGroup {
display: flex;
flex-direction: row;
}

.hcMirador {
width: 100%;
height: 100%;
}
.hcMirador img {
width: 100%;
height: auto;
}

.brandName {
font-weight: 700;
}

.brandName img {
height: 50px;
width: auto !important;
}

.chBrandHuc .bgColorBrand1 {
background-image: -webkit-linear-gradient(25deg, #03dbe8 40%, #ddf279 100%) !important;
}
.chBrandHuc .colorBgGrey {
background-color: #edf7f3 !important;
}

.chBrandHuygens .bgColorBrand1 {
background-image: -webkit-linear-gradient(25deg, #082045 40%, #082045 100%) !important;
}
.chBrandHuygens .colorBgGrey {
background-color: #efefef !important;
}
body {

color: #000000;
}

a {
color: #0087d4;
}

.colorBgGrey {
background-color: #f6f6f6;
}

.colorBgGreyLight {
background-color: #fafafa;
}

.colorBgGreyPlus {
background-color: #dddddd;
}

.hcTxtColorGreyMid {
color: #999999;
}

.bgColorBrand1 {
background-color: #265955;
color: #fff;
}

.bgColorBrand1 a {
color: #fff;
}

.colorBgGrey {
background-color: #efefef !important;
}

.hcFeedbackError {
border-bottom: 3px solid #d06f5d !important;
}

.hcFeedbackSucces {
border-bottom: 3px solid #64a264 !important;
}

.hcFormDiv {
border: 2px solid #efefef;
padding: 2rem 1rem;
}

hr {
color: #dee6ed;
background-color: #dee6ed;
}

.bgColorBrandIK {
background-color: #265955;
}
.bgColorBrandIK a {
color: #fff;
}

.hcIKBgLight {
background-color: #f8f4f1;
}

.hcSmallTxt {
font-size: 0.9rem;
}

.hcDataLabel {
font-size: 0.9rem;
font-weight: 700;
margin-bottom: -0.3rem;
}

.hcIKkImageBlock {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #f8f4f1;
padding: 1rem;
}
.hcIKkImageBlock img {
max-width: 500px;
}

.hcIKheaderSplit {
display: flex;
flex-direction: column;
}

@media (min-width: 500px) {
.hcIKheaderSplit {
flex-direction: row;
}
}
.hcIKresultCard {
display: flex;
flex-direction: column;
margin: -1rem;
padding: 1rem;
}

.hcIKresultCardInfo {
display: flex;
flex-direction: column;
}

@media (min-width: 500px) {
.hcIKresultCardInfo {
flex-direction: row;
}
}
.hcIKkImageBlockResult {
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
background-color: #f8f4f1;
padding: 0.5rem;
}
.hcIKkImageBlockResult img {
width: 100px;
height: auto;
}

.hcIKstate {
display: flex;
width: 100%;
justify-content: flex-end;
align-items: center;
}

.hcIKstateLabelBlock {
padding-right: 0.5rem;
text-align: right;
}

.hcIKstateIconBlock {
padding: 0.2rem 0.2rem 0.2rem 0.2rem;
display: flex;
align-items: center;
justify-content: center;
}
.hcIKstateIconBlock img {
height: 20px;
width: auto;
}

.hcIKselectedFacet {
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-items: center;
width: 100%;
white-space: nowrap;
border-bottom: 1px solid #e6e6e6;
font-size: 0.9rem;
}

.hcIKselectedFacetBuffer {
width: 100%;
display: flex;
flex-direction: row;
flex-wrap: wrap;
}

.hcIKselectedFacetItem {
display: flex;
flex-direction: row;
align-items: center;
margin-right: 1rem;
margin-bottom: 0.3rem;
}

.hcIKselectedFacetVal {
padding: 0rem 0.3rem;
background-color: #e4e4e4;
border-radius: 3px;
margin-left: 0.5rem;
display: flex;
flex-direction: row;
align-items: center;
}
.hcIKselectedFacetVal .hcIcon {
padding: 0 0 0 0.3rem;
opacity: 0.7;
}

.hcIKidBlock {
width: 60px;
text-align: right;
}

.hcBrandLogo img {
width: 80px;
height: auto;
margin-top: 0.8rem;
}

.hcBrandLogo {
cursor: pointer;
}

.removeThisClass-Rob {
width: 100%;
}
.removeThisClass-Rob img {
width: 100%;
height: auto;
}

.hcEqual {
width: 100%;
}

.hcIKresultCardInfo .hc2columns > * {
flex: 1;
max-width: 50%;
}

.loader {
display: block;
margin: 100px 0 300px 60px;
font-weight: bold;
}

.hcClickable {
color: #265955;
cursor: pointer;
display: inline;
}

.hcPageSelector {
height: 36px;
font-size: 12px !important;
}

.hcFacetItem {
cursor: pointer;
}

.hcFacetReset {
float: right;
}

.hcFacetValues {
margin-right: 4px;
}

.hcTitle{
display: inline-block;
font-weight: bold;
font-size: 24px;
padding-top: 18px;
}

.hcResultListDetail {
display: block;
position: relative;
width: 780px;
min-height: 400px;
border: 1px solid #265955;
padding: 10px 16px;
margin: 0 0 20px 0;
background-color: #F4ECE2;
border-radius: 6px;
}

.detailLine {
font-size: smaller;
}

.detailLine strong {
color: #6b6b6b;
}

.hcResultListDetail h2 {
font-weight: bold;
font-size: 20px;
color: #265955;
}

.hcManuscriptImage {
float: right;
max-height: 300px;
border: 1px solid #265955;
margin-left: 5px;
margin-bottom: 10px;
}

.ManuscriptListBtns {
list-style: none;
margin-top: 16px;
}

.ManuscriptListBtns li {
display: inline-block;
color: #ffffff;
background-color: #265955;
font-weight: bold;
font-size: 14px;
padding: 4px 6px;
width: 140px;
text-align: center;
cursor: pointer;
}

.ManuscriptListBtns li:last-child {
background-color: #3b0b00;
visibility: hidden;
}

.facsimileList{
list-style: none;
}

.facsimileList li{
display: inline-block;
font-size: 14px;
margin-bottom: 10px;
color: #265955;
cursor: pointer;
}

.facsimileList li:hover {
font-weight: bold;
}

.facsimileList li:first-child{
color: #000000;
}

.detailNav{
list-style: none;
border-bottom: 1px solid #265955;
}

.detailNav li{
display: inline-block;
width: 200px;
border-top: 1px solid #dddddd;
border-right: 1px solid #dddddd;
border-left: 1px solid #dddddd;
font-size: 14px;
color: #dddddd;
padding-left: 6px;
margin-left: 0;
margin-right: 16px;
background-color: #265955;
cursor: pointer;
border-top-right-radius: 15px;
}

.detailNav li.detailActive{
background-color: #265955;
color: #ffffff;
}

.detailArea{
border: 1px solid #dddddd;
display: block;
width: 640px;
padding: 10px 8px;
}

.collectionBrowser{
display: block;
text-align: center;
font-size: smaller;
margin-bottom: 20px;
border-bottom: 1px solid #dddddd;
padding-bottom: 20px;
}

.ecoDetailTable {
display: table;
}

.ecoDetailRow {
display: table-row;
font-size: 14px;
padding-bottom: 0;
}

.ecoLabelCell, .ecoCell {
display: table-cell;
padding-bottom: 2px;
vertical-align: top;
}

.ecoDetailRow:last-child .ecoLabelCell, .ecoDetailRow:last-child .ecoCell{
padding-bottom: 4px;
}

.ecoLabelCell {
width: 180px;
min-width: 180px;
max-width: 180px;
border-right: 1px solid #aaa;
font-weight: bold;
color: #265955;
}

.ecoCell{
padding-left: 20px;
}

.ecoCellClickable{
cursor: pointer;
color: #265955;
}

.ecoCellClickable:hover, .hcClickable:hover, .detailBack:hover {
font-weight: bold;
}

.detailH3{
margin-bottom: 20px;
}

.facsimileImg{
width: 340px;
cursor: pointer;
border: 1px solid #265955;
}

.facetAmount{
display: inline;
font-weight: bold;
color: #777777;
}

.detailBack{
cursor: pointer;
font-size: 14px;
color: #265955;
text-align: right;
}

.arrowNav{
display: inline-block;
font-size: x-large;
margin-left: 12px;
cursor: pointer;
}

.selectNav{
display: inline-block;
font-size: small;
width: 500px;
margin-left: 12px;
}

.amountNav{
display: inline-block;
font-weight: bold;
margin-left: 20px;
}

.backToResults{
display: inline;
cursor: pointer;
color: #0087d4
}

.hcRow{
display: table-row;
width: 1140px;
}

.hcColumn{
display: table-cell;
width: 590px;
padding-right: 100px;
}

.puzzle{
width: 300px;
border: 1px solid #000000;
margin: 20px 0 10px 0;
}

.smallLink{
cursor: pointer;
font-size: 14px;
color: #0087d4;
}

.smallLink2{
cursor: pointer;
font-size: 18px;
color: #0087d4;
}

.ecoHomeBox{
margin-bottom: 40px;
}

.docSeparator{
border-bottom: 1px solid #265955;
}

.iiifImg{
width: 50px;
}

.iiifHolder{
text-align: center;
width: 340px;
}

.fullviewHeader{
font-size: 14px;
font-weight: bold;
color: #265955;
display: table-cell;
}

.navUp {
cursor: pointer;
display: block;
bottom: 10px;
position: absolute;
bottom: 5px;
right: 10px;
color: #265955;
}

.headerLink{
background-color: #b28248;
font-weight: bold;
display: inline-block;
padding:0 12px;
line-height: 5%;
height: 18px;
margin-top: 12px;
border-radius: 20px;
}




